import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { getPostStatsNumberAfterSync } from '@/helper/utils'
import { apiService } from '@/services/api-services'
import { dispatcher, PostActionModel } from '@/stores/dispatcher'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, observable, reaction, toJS, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class OwnerSectionController {
  private _unsubcrible = new Subject()
  @observable summaryRewardData: any[] = []

  constructor() {
    dispatcher.$postChanged.pipe(takeUntil(this._unsubcrible)).subscribe((postAction) => {
      this.syncPostAction(postAction)
    })
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }

  @action syncPostAction(postAction: PostActionModel) {
    walletStore.changeUserProfile({
      totalFollowers: this.totalFollowers,
      totalPosts: getPostStatsNumberAfterSync(this.userProfile?.totalPosts, postAction.type),
    })
  }

  @computed get userProfile() {
    return walletStore.userProfile
  }

  @computed get avatar() {
    return this.userProfile?.avatar.url
  }

  @computed get username() {
    return this.userProfile?.username
  }

  @computed get totalFollowings() {
    return this.userProfile?.totalFollowings
  }

  @computed get totalFollowers() {
    return this.userProfile?.totalFollowers
  }

  @computed get totalPosts() {
    return this.userProfile?.totalPosts
  }
}

export const communityOwnerBoardController = new OwnerSectionController()
