import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { computed, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { dispatcher } from './dispatcher'
import { walletStore } from './wallet-store'
import { takeUntil } from 'rxjs/operators'

export class RewardStore {
  @observable summaryRewardData: any[] = []
  private _disposers: any[]
  private _unsubcrible = new Subject()

  constructor() {
    this.getRewardSummaryData()
    this._disposers = [
      reaction(
        () => walletStore.jwt,
        (profile) => {
          if (profile) this.getRewardSummaryData()
        },
        { fireImmediately: true }
      ),
    ]

    dispatcher.$postChanged.pipe(takeUntil(this._unsubcrible)).subscribe((post) => {
      this.getRewardSummaryData()
    })
    dispatcher.$commentCreated.pipe(takeUntil(this._unsubcrible)).subscribe((comment) => {
      this.getRewardSummaryData()
    })
    dispatcher.$upvoteCreated.pipe(takeUntil(this._unsubcrible)).subscribe((like) => {
      this.getRewardSummaryData()
    })
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }

  @asyncAction *getRewardSummaryData() {
    try {
      const res = yield apiService.earningActions.getEarnDataSummary()
      // console.log(res.data)
      if (res.data) {
        this.summaryRewardData = res.data
      }
    } catch (error) {}
  }

  @computed get totalPostEarn() {
    const postData = this.summaryRewardData.find((item) => item.type === 'post')
    return postData?.count || 0
  }

  @computed get totalPostReward() {
    const postData = this.summaryRewardData.find((item) => item.type === 'post')
    return postData?.totalReward || 0
  }

  @computed get totalUpvoteEarn() {
    const postData = this.summaryRewardData.find((item) => item.type === 'upvote')
    return postData?.count || 0
  }

  @computed get totalUpvoteReward() {
    const postData = this.summaryRewardData.find((item) => item.type === 'upvote')
    return postData?.totalReward || 0
  }

  @computed get totalCommentEarn() {
    const commentData = this.summaryRewardData.find((item) => item.type === 'comment')
    return commentData?.count || 0
  }

  @computed get totalCommentReward() {
    const commentData = this.summaryRewardData.find((item) => item.type === 'comment')
    return commentData?.totalReward || 0
  }

  @computed get totalInviteEarn() {
    const commentData = this.summaryRewardData.find((item) => item.type === 'referral')
    return commentData?.count || 0
  }

  @computed get totalInviteReward() {
    const commentData = this.summaryRewardData.find((item) => item.type === 'referral')
    return commentData?.totalReward || 0
  }

  @computed get totalAcceptInviteEarn() {
    const commentData = this.summaryRewardData.find((item) => item.type === 'referral-accept')
    return commentData?.count || 0
  }

  @computed get totalAcceptInviteReward() {
    const commentData = this.summaryRewardData.find((item) => item.type === 'referral-accept')
    return commentData?.totalReward || 0
  }

  @computed get totalOtherEarn() {
    const commentData = this.summaryRewardData.find((item) => item.type === 'other')
    return commentData?.count || 0
  }

  @computed get totalOtherReward() {
    const commentData = this.summaryRewardData.find((item) => item.type === 'other')
    return commentData?.totalReward || 0
  }

  @computed get totalReward() {
    return this.totalPostReward + this.totalCommentReward + this.totalUpvoteReward + this.totalInviteReward + this.totalAcceptInviteReward + this.totalOtherReward
  }
}

export const rewardStore = new RewardStore()
