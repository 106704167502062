






























































































































































































































































































































































































import { communityOwnerBoardController } from '@/modules/common/components/section/owner-section-controller'
import { rewardStore } from '@/stores/rewardStore'
import { walletStore } from '@/stores/wallet-store'
import { Component, PropSync, Vue } from 'vue-property-decorator'
import { accountSettingController } from '../auth/account-setting-controller'
import { snackController } from '../snack-bar/snack-bar-controller'

@Component({
  components: {
    avatar: () => import('@/modules/community/common/components/avatar.vue'),
    notification: () => import('@/components/notification/notification.vue'),
  },
})
export default class Drawer extends Vue {
  @PropSync('drawer') drawerDialog!: boolean
  shareProfile() {
    this.drawerDialog = false
    this.wallet.shareProfile()
  }
  wallet = walletStore
  controller = communityOwnerBoardController
  rewardStore = rewardStore

  accountSettingController = accountSettingController

  connectWallet() {
    this.drawerDialog = false
    this.wallet.showConnectWalletDialog(true)
  }

  openSettingDialog() {
    this.drawerDialog = false
    accountSettingController.setOpenDialog(true)
  }

  login() {
    this.drawerDialog = false
    this.wallet.signIn()
  }

  disconnect() {
    this.wallet.disconnect()
  }

  copyWalletAddress() {
    if (this.wallet.userProfile) navigator.clipboard.writeText(this.wallet.userProfile.walletAddress ?? '')
  }

  getProfileId(profile) {
    if (!profile) return
    return profile.unique_id
  }

  get isCommunityRoute() {
    const routeName = (this.$route?.matched || [])[0]?.name
    return routeName === 'Community'
  }
}
